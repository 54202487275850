'use client'

import React, {
  ReactElement,
} from 'react'
import { useCurrentUser } from '@hooks'

const DefaultFallbackComponent = (): ReactElement => <></>

type Props = object

const withUser = <T extends Props>(
  WrappedComponent: React.ComponentType<T>,
  FallbackComponent = DefaultFallbackComponent,
): React.ComponentType<T> => {
  const ComponentWithSession = (props: T): ReactElement => {
    const user = useCurrentUser()

    if (user) {
      return (
        <WrappedComponent
          user={user}
          {...(props as T)} />
      )
    }

    return <FallbackComponent />
  }

  return ComponentWithSession
}

export default withUser
