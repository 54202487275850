'use client'

import Link from 'next/link'
import React, {
  ReactElement,
} from 'react'
import User, {
  UserFallback,
} from './User'
import withUser from '@components/Auth/withUser'
import { css, cx } from 'styled-system/css'
import { flex } from 'styled-system/patterns'
import { invalidateHomePath } from '@/app/actions'
import { useMenuContext } from '@/hooks'

const AuthenticatedUser = withUser(User, UserFallback)

const Navigation = (): ReactElement => {
  const {
    open: menuIsOpen,
    setIsOpen: setMenuIsOpen,
  } = useMenuContext()

  const onClickHome = () => {
    try {
      invalidateHomePath()
    } catch (error) {
      console.log(error)
    }
  }

  return (<nav
    className={styles.main}
    id='nav'>
    <div className={styles.wrapper}>
      <span
        className={styles.menu}
        onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <i className={cx(
          styles.icon,
          menuIsOpen ? 'fa-slash' : 'fa-stream',
        )}/>
        <span className={styles.menuText}>
          Menu
        </span>
      </span>
      <Link
        className={styles.logo}
        href='/'
        onClick={onClickHome}
        prefetch={false}>SL&ENT</Link>
      <AuthenticatedUser />
    </div>
  </nav>)
}

const styles = {
  main: cx(
    flex({
      align: 'stretch',
      justify: 'center',
    }),
    css({
      bg: 'header',
      height: 14,
      layerStyle: 'borderBottom',
      pos: 'fixed',
      px: {
        base: 4,
        lg: 8,
      },
      top: 0,
      w: '100%',
      zIndex: 1000,
    }),
  ),
  wrapper: css({
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: {
      base: 'minmax(0, 25fr) minmax(0, 50fr) minmax(0, 25fr)',
      lg: 'minmax(0, 20fr) minmax(0, 80fr)',
    },
    justifyContent: 'space-between',
    maxW: 'bodyWidth',
    w: '100%',
  }),
  menu: css({
    hideFrom: 'lg',
  }),
  menuText: css({
    display: 'none',
  }),
  logo: cx(
    flex({
      align: 'center',
      justify: 'center',
    }),
    css({
      color: 'headerText',
      display: {
        lg: 'block',
      },
      fontWeight: 700,
      gridColumn: 1,
      _hover: {
        color: 'headerText',
      },
    }),
  ),
  icon: cx(
    'fas',
    css({
      color: 'headerText',
      display: 'block',
      mr: 3,
      textAlign: 'center',
      w: '6 !important',
    }),
  ),
}

export default Navigation
