import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-client-cookies/dist/provider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/src/components/Auth/UserProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ControlCenter/Container.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ControlCenter/ControlCenter.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ControlCenter/Notifications.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ControlCenter/UserAccountPrompt.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/Root.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/Toaster.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MenuProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/NotificationsProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/WebSocketProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/global.css");
import(/* webpackMode: "eager" */ "/app/src/styles/colors.sass")