import WebSocketAsPromised from 'websocket-as-promised'

const API_URL = (process.env.NEXT_PUBLIC_API_URL || 'https://api.slaent.io').replace('https', 'wss')

class WebSocketManager {
  instance?: WebSocketAsPromised

  constructor (accessToken: string, path: string) {
    const accessTokenQueryParam = accessToken ? `?accessToken=${accessToken}` : ''
    const url = `${API_URL}${path}${accessTokenQueryParam}`

    this.instance = new WebSocketAsPromised(
      url,
      {
        packMessage: JSON.stringify,
        unpackMessage: (data: any) => JSON.parse(data),
        attachRequestId: (data, id) => Object.assign({ id }, data), // attach requestId to message as `id` field
        extractRequestId: data => data && data.id, // read requestId from message `id` field
      }
    )
  }

  getInstance (): WebSocketAsPromised|undefined {
    if (this.instance) {
      return this.instance
    }
  }
}

export default WebSocketManager
