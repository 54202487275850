'use client'

import Link from 'next/link'
import React, {
  ReactElement,
} from 'react'
import { css, cx } from 'styled-system/css'
import { hstack, vstack } from 'styled-system/patterns'

const AccountPrompt = (): ReactElement => {
  return (
    <div className={styles.main}>
      <div className={styles.copy}>
        <h3 className={styles.header}>Become a member</h3>
        <p>Create an account and join in on the banter.</p>
      </div>
      <Link
        className={styles.button}
        href='/login'
        prefetch={false}>
        <i className='fas fa-user-astronaut'/>
        Log In / Sign Up
      </Link>
    </div>
  )
}

const styles = {
  main: vstack({
    borderTop: '1px solid token(colors.foregroundAlt)',
    gap: 4,
    py: 6,
    w: '100%',
  }),
  copy: vstack({
    gap: 2,
    fontSize: '0.875rem',
  }),
  header: css({
    fontWeight: 700,
  }),
  button: cx(
    hstack({
      gap: 2,
    }),
    css({
      bg: 'none',
      border: '1px solid token(colors.text)',
      borderRadius: 'full',
      fontSize: '0.75rem',
      fontWeight: 700,
      px: 3,
      py: 2,
      _hover: {
        bg: 'white',
        color: 'black',
      },
    }),
  ),
}

export default AccountPrompt
