'use client'

import Avatar from '@components/shared/UserAvatar'
import Link from 'next/link'
import NotificationBell from '@components/shared/NotificationBell'
import React, {
  ReactElement,
} from 'react'
import { css, cx } from 'styled-system/css'
import { hstack, vstack } from 'styled-system/patterns'
import type { UserType } from '@/types/users'

type Props = {
  user: UserType
}

const User = ({
  user,
}: Props|any): ReactElement => {
  const onGoToBottom = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight)
    }, 250)
  }

  return (
    <div className={styles.main}>
      <Link
        className={styles.bellLink}
        href='/notifications'
        prefetch={false}>
        <NotificationBell classNames={styles.bell}/>
      </Link>
      <Link
        href='/settings'
        prefetch={false}>
        <div className={styles.meta}>
          <div className={styles.profile}>
            <span className={styles.header}>Welcome back</span>
            <span><strong>{user.username}</strong></span>
          </div>
          <Avatar image={user.avatar} />
        </div>
      </Link>
      <span
        className={styles.iconBottom}
        onClick={onGoToBottom}>
        <i className='fas fa-sort-down'/>
        <span>Bottom</span>
      </span>
    </div>
  )
}

export const UserFallback = (): ReactElement => {
  return (
    <div className={styles.main}>
      <Link
        className={styles.signIn}
        href='/login'
        prefetch={false}>
        <i className='fas fa-user-astronaut fa-lg'/>
        Log In / Sign Up
      </Link>
    </div>
  )
}

const styles = {
  main: cx(
    hstack({
      gap: 2,
    }),
    css({
      justifyContent: 'flex-end',
    }),
  ),
  meta: cx(
    hstack({
      gap: 4,
    }),
    css({
      pos: 'relative',
    }),
  ),
  bellLink: css({
    color: 'headerText',
    _hover: {
      color: 'headerText',
    },
  }),
  bell: css({
    hideFrom: 'lg',
  }),
  profile: cx(
    vstack({
      alignItems: 'end',
      gap: 0.5,
    }),
    css({
      color: 'headerText',
      hideBelow: 'lg',
    }),
  ),
  header: css({
    fontSize: '0.875rem',
  }),
  signIn: cx(
    hstack({
      gap: 2,
    }),
    css({
      bg: 'none',
      border: '1px solid token(colors.text)',
      borderRadius: 'full',
      flex: 'none',
      fontSize: '0.75rem',
      fontWeight: 700,
      px: 3,
      py: 2,
      _hover: {
        bg: 'white',
        color: 'black',
      },
    }),
  ),
  button: css({
    border: '1px solid token(colors.headerText)',
    borderRadius: 'md',
    color: 'headerText',
    p: 2,
  }),
  buttonText: css({
    display: 'none',
  }),
  text: css({
    hideBelow: 'lg',
  }),
  iconBottom: css({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    w: 4,
    '& span': {
      display: 'none',
    },
  }),
}

export default User
