'use client'

import Link from 'next/link'
import React, {
  ReactElement,
} from 'react'
import styles from './styles'
import { cx } from 'styled-system/css'
import { usePathname } from 'next/navigation'

type Props = {
  header: string
  headerUrl: string
  icon: string
  links: any[]
}

const defaultMatchUrl = (url: string, path: string): boolean => path.includes(url)

const Container = ({
  header,
  headerUrl,
  icon,
  links,
}: Props): ReactElement => {
  const pathname = usePathname()
  const iconClassName = cx(styles.icon, icon)

  return (
    <div className={styles.container}>
      <Link
        className={styles.linkHeader}
        href={headerUrl}
        prefetch={false}>
        <h3 className={styles.header}>
          <i className={cx('icon', iconClassName)}/>
          <span className='header'>
            {header}
          </span>
        </h3>
      </Link>
      <div className={cx('links', styles.links)}>
        {
          links.map(({
            isRead = false,
            meta = null,
            title,
            url,
          }, index) => (
            (<Link
              {...(defaultMatchUrl(url.path, pathname) ? { 'data-selected': true } : '')}
              as={url.path}
              className={cx('group', styles.link, isRead ? styles.linkIsRead : null)}
              href={{
                pathname: url.pathname,
              }}
              key={index}
              prefetch={false}>
              <span
                className={cx('title', styles.linkTitle)}
                key='title'>{title.replace(/&amp;/g, '&')}</span>
              {
                meta ?
                  <div
                    className={cx('meta', styles.linkMeta)}
                    key='meta'>
                    {meta}
                  </div>
                  : null
              }
            </Link>)
          ))
        }
      </div>
    </div>
  )
}

export default Container
