import { css, cx } from 'styled-system/css'
import { flex, vstack } from 'styled-system/patterns'

const styles = {
  main: cx(
    vstack({
      alignItems: 'start',
      justifyContent: 'space-between',
    }),
    css({
      display: {
        base: 'none',
        lg: 'block',
      },
      gridColumn: 1,
      '&[data-menu-open=true]': {
        bg: 'background/98',
        display: 'block !important',
        h: '100%',
        left: 0,
        position: {
          base: 'fixed',
          lg: 'static',
        },
        top: 0,
        w: '100%',
        zIndex: 999,
        '& .wrapper': {
          px: 4,
          pb: 4,
          pt: 20,
        },
      },
      '&[data-menu-collapsed=true]': {
        '& .wrapper': {
          overflowY: 'hidden',
          pr: 4,
        },
        '& .header, & .links, & .footer': {
          display: 'none',
        },
        '& .icon': {
          mr: 0,
        },
        '& .collapse-button': {
          justifyContent: 'center',
        },
      },
    }),
  ),
  wrapper: cx(
    vstack({
      alignItems: 'start',
      justifyContent: 'space-between',
    }),
    css({
      borderRight: '1px solid token(colors.foregroundAlt)',
      minH: {
        base: '100%',
        lg: 'calc(100vh - var(--nav-top))',
      },
      minW: 0,
      overflowY: 'scroll',
      position: {
        lg: 'sticky',
      },
      pr: 8,
      pt: 8,
      transform: 'translate3d(0, calc(-3.5rem + var(--nav-top)), 0)',
      top: 14,
      w: '100%',
      '&[data-scrolled-bottom=true]': {
        transform: 'none',
      },
      '&::-webkit-scrollbar': {
        bg: 'transparent',
        w: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        bg: 'token(colors.foreground)',
        borderRadius: 'full',
      },
    }),
  ),
  content: vstack({
    alignItems: 'start',
    gap: 4,
    w: '100%',
  }),
  header: cx(
    flex({
      align: 'center',
    }),
    css({
      fontWeight: 700,
    }),
  ),
  icon: cx(
    'fal',
    css({
      display: 'flex !important',
      justifyContent: 'center',
      mr: 3,
      w: '6 !important',
    }),
  ),
  dot: cx(
    'dot',
    'fas',
    css({
      color: 'highlight',
      fontSize: '0.375rem',
      position: 'absolute',
      transform: 'translateX(-0.75rem) translateY(-0.825rem)',
    }),
  ),
  container: css({
    w: '100%',
  }),
  links: cx(
    vstack({
      alignItems: 'start',
      gap: 1,
    }),
    css({
      mt: 2,
      pl: 6,
      w: '100%',
    }),
  ),
  link: css({
    display: 'block',
    mx: '-0.5rem',
    position: 'relative',
    px: 5,
    py: 2,
    rounded: 'sm',
    w: '100%',
    _hover: {
      bg: 'highlight',
      color: 'highlightText !important',
      '& .meta, & .title, & .dot': {
        color: 'inherit !important',
      },
    },
    _selected: {
      bg: 'highlight',
      color: 'highlightText !important',
      '& .meta': {
        color: 'inherit',
      },
    },
  }),
  linkIsRead: css({
    color: 'textAlt !important',
    '& .title': {
      fontWeight: '400 !important',
    },
  }),
  linkHeader: css({
    color: 'text',
    position: 'relative',
    _hover: {
      color: 'linkHover',
      '& .unread': {
        color: 'text',
      },
    },
  }),
  linkTitle: css({
    display: 'block',
    fontSize: '0.875rem',
    fontWeight: 700,
    truncate: true,
    w: '100%',
  }),
  linkMeta: css({
    color: 'textAlt',
    fontSize: '0.75rem',
    mt: 1,
  }),
  footerWrapper: css({
    w: '100%',
  }),
  footer: css({
    color: 'textAlt',
    fontSize: '0.875rem',
    pl: 9,
    pr: 4,
    pt: 4,
  }),
  footerCopyright: css({
    fontWeight: 400,
  }),
  footerLinks: cx(
    flex({
      direction: 'column',
    }),
    css({
      my: 8,
    }),
  ),
  footerLink: css({
    color: 'textAlt',
    _hover: {
      color: 'linkHover',
    },
    '& + &': {
      mt: 2,
    },
  }),
  collapse: cx(
    flex({
      justify: 'end',
    }),
    css({
      bg: 'background',
      borderBottom: 'none',
      borderLeft: 'none',
      borderRadius: 0,
      borderRight: 'none',
      borderTop: '1px solid token(colors.foregroundAlt)',
      bottom: 0,
      hideBelow: 'lg',
      position: 'sticky',
      mt: 4,
      px: 0,
      py: 8,
      w: '100%',
      _hover: {
        color: 'linkHover',
        '& .icon': {
          color: 'inherit !important',
        },
      },
    }),
  ),
  collapseIcon: cx(
    'fas',
    css({
      mr: 0,
      w: 'auto !important',
    }),
  ),
}

export default styles
