'use client'

import React, {
  ReactNode,
  useState,
} from 'react'
import { Context } from './NotificationsContext'

interface ProviderProps {
  children: ReactNode
}

function Provider ({
  children,
}: ProviderProps) {
  const state = useState(0)

  return ( <>
    <Context.Provider value={state}>{children}</Context.Provider>
  </>)
}

export default Provider
