'use client'

import Navigation from '@components/Navigation'
import React, {
  ReactElement,
} from 'react'
import { css, cx } from 'styled-system/css'
import { flex } from 'styled-system/patterns'
import { useMenuContext } from '@/hooks'

type Props = {
  controlCenter: ReactElement
  children: ReactElement
}

const Root = ({
  controlCenter,
  children,
}: Props): ReactElement => {
  const {
    collapsed: menuIsCollapsed,
    hidden: navIsHidden,
  } = useMenuContext()

  return (
    <div
      className={styles.root}
      data-nav-is-hidden={navIsHidden}
      style={{ '--nav-top': navIsHidden ? '0rem' : '3.5rem' } as any}>
      <Navigation/>
      <div
        className={styles.slaent}
        data-menu-collapsed={menuIsCollapsed}>
        {controlCenter}
        <main className={styles.main}>
          {children}
        </main>
      </div>
    </div>
  )
}

const styles = {
  root: cx(
    flex({
      align: 'center',
      direction: 'column',
    }),
    css({
      minH: '100vh',
      pt: 14,
      px: {
        base: 4,
        lg: 8,
      },
      '&[data-nav-is-hidden=true]': {
        '& #nav': {
          transform: 'translate3d(0, -100%, 0)',
        },
      },
    }),
  ),
  slaent: css({
    display: {
      base: 'block',
      lg: 'grid',
    },
    gridTemplateColumns: {
      lg: 'minmax(0, 20fr) minmax(0, 80fr)',
    },
    maxW: 'bodyWidth',
    minH: 'calc(100vh - var(--nav-top))',
    width: '100%',
    '&[data-menu-collapsed=true]': {
      gridTemplateColumns: {
        lg: 'minmax(0, auto) minmax(0, 100fr)',
      },
    },
  }),
  main: cx(
    flex({
      align: 'start',
    }),
    css({
      gridColumn: 'span 1 / span 2',
    }),
  ),
}

export default Root
