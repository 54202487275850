'use client'

import Avatar from '@components/shared/UserAvatar'
import Link from 'next/link'
import NotificationBell from '@components/shared/NotificationBell'
import React, {
  ReactElement,
  useEffect,
} from 'react'
import countBy from 'lodash.countby'
import defaultStyles from './styles'
import toast from 'react-hot-toast'
import { css, cx } from 'styled-system/css'
import { hstack, vstack } from 'styled-system/patterns'
import { markNotificationRead } from '@/app/actions'
import {
  useNotificationsContext,
  useWebSocketContext,
} from '@hooks'
import type { NotificationMessageType } from 'src/types/websocket'
import type {
  NotificationPostType,
  NotificationThreadType,
} from '@/types/posts'
import type { UserType } from '@/types/users'

type Props = {
  notifications: (NotificationPostType|NotificationThreadType)[]
  user: UserType
}

const Notifications = ({
  notifications,
  user,
}: Props): ReactElement => {
  const { ws } = useWebSocketContext()

  const [, setUnreadCount] = useNotificationsContext()

  useEffect(() => {
    const { false: unreadTotal = 0 } = countBy(notifications, 'is_read')
    const count = Math.min(unreadTotal, 9)

    setUnreadCount(count)
  }, [notifications])

  useEffect(() => {
    (async (): Promise<void> => {
      const onOpen = async () => {
        await ws.sendPacked({
          type: 'user.join',
          user_id: user.id,
        })

        await ws?.onUnpackedMessage.addListener(
          (message: NotificationMessageType) => {
            switch (message.type) {
              case 'notification.new': {
                setUnreadCount(Math.min(message.total, 9))

                const {
                  id,
                  offset,
                  thread_info: {
                    id: threadId,
                    slug,
                    title,
                  },
                  user_avatar: avatar,
                  username,
                } = message.payload
                const page = Math.ceil(offset / 50)

                toast.custom(t => (
                  <div onClick={() => onMarkAsReadClick(id, t.id)}>
                    <Link
                      className={cx(
                        styles.notification,
                        t.visible ? styles.enter : styles.leave,
                      )}
                      href={`/s/${slug}/t/${threadId}/page/${page}?p=${id}`}
                      prefetch={false}>
                      <div className={styles.meta}>
                        <Avatar image={avatar}/>
                        <div className={styles.info}>
                          <h4 className={styles.title}>{username} mentioned you</h4>
                          <span className={styles.thread}>{title}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ),
                {
                  duration: 10000,
                  position: 'top-right',
                })

                return
              }
            }
          }
        )
      }

      if (!ws?.isOpened) {
        await ws?.onOpen.addListener(onOpen)
      } else {
        await onOpen()
      }
    })()

    return () => {
      (async (): Promise<void> => {
        if (ws?.isOpened) {
          await ws?.sendPacked({
            type: 'user.leave',
            user_id: user.id,
          })
        }
      })()

      return ws?.onUnpackedMessage.removeAllListeners()
    }
  }, [ws])

  const onMarkAsReadClick = async (id: number, toastId: string) => {
    try {
      await markNotificationRead(user.id, id)

      setUnreadCount((prev: number) => prev - 1)
      toast.remove(toastId)
    } catch {
      //
    }
  }

  return (<>
    <Link
      className={defaultStyles.linkHeader}
      href='/notifications'
      prefetch={false}>
      <h3 className={defaultStyles.header}>
        <NotificationBell classNames={styles.bell}/>
        <span className='header'>
          Notifications
        </span>
      </h3>
    </Link>
  </>)
}

const styles = {
  notification: cx(
    hstack({
      gap: 8,
    }),
    css({
      bg: 'foreground !important',
      border: '1px solid token(colors.foregroundAlt) !important',
      borderRadius: 'md !important',
      boxShadow: '8px 8px 24px rgba(0, 0, 0, 0.9)',
      color: 'text !important',
      maxWidth: '20rem',
      p: 4,
    })
  ),
  enter: css({
    animation: 'enter 0.5s ease-out',
  }),
  leave: css({
    animation: 'leave 0.5s ease-out',
  }),
  meta: cx(
    hstack({
      gap: 4,
    }),
    css({
      minWidth: 0,
    }),
  ),
  info: cx(
    vstack({
      alignItems: 'flex-start',
      gap: 1,
    }),
    css({
      minWidth: 0,
    }),
  ),
  bell: css({
    mr: 3,
  }),
  title: css({
    fontWeight: 700,
    truncate: true,
    w: '100%',
  }),
  thread: css({
    color: 'textAlt',
    fontSize: '0.75rem',
    truncate: true,
    w: '100%',
  }),
}

export default Notifications
